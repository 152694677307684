@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/custom.scss';

// //Import Light mode
@import './assets/scss/style.scss';

//Import Dark RTL mode
// @import './assets/scss/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss/style-dark.scss';
